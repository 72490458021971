import React, { Component } from "react";
import auth from "./Auth";
import { Menu } from "@material-ui/icons";
import { HashRouter as Router, NavLink, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import UpdateUserDetails from "./UpdateUserDetail";
import ChangePassword from "./ChangePassword";
import ClearEntries from "./ClearEntries";

const cookies = new Cookies();

export default class Header extends Component {
  state = {
    permissions: cookies.get("permissions"),
    setting: cookies.get("setting"),
    fileLoaded: false,
  };

  handleCallback = (editData) => {
    if (editData) {
      this.setState({ fileLoaded: true });
    }
  };

  componentDidUpdate() {
    if (this.state.fileLoaded) {
      this.setState({ fileLoaded: false });
    }
  }

  render() {
    auth.checklogin();
    return (
      <div>
        <nav className="navbar navbar-expand-xl navbar-dark fixed-top hk-navbar bg-danger">
          <a
            id="navbar_toggle_btn"
            className="navbar-toggle-btn nav-link-hover"
          >
            <Menu />
          </a>

          <Link className="navbar-brand" to="/app">
            <div className="media">
              <div className="media-img-wrap">
                <div className="avatar avatar-sm">
                  {cookies.get("logo") !== "null" && cookies.get("logo") ? (
                    <img
                      src={
                        process.env.REACT_APP_API_LINK +
                        "companyLogos/" +
                        cookies.get("logo")
                      }
                      alt="user"
                      className="avatar-img thumbnail"
                    />
                  ) : (
                    <img
                      src="/247-logo-sq.png"
                      alt="user"
                      className="avatar-img rounded-circle thumbnail"
                    />
                  )}
                </div>
              </div>
              <div className="media-body">
                <h4 className="mt-10 ml-5 text-white">
                  {cookies.get("transText")
                    ? cookies.get("transText")
                    : "247i PAYROLL"}
                </h4>
              </div>
            </div>
          </Link>
          <ul className="navbar-nav hk-navbar-content order-xl-2">
            <li className="nav-item dropdown dropdown-authentication">
              <a
                className="nav-link dropdown-toggle no-caret"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="media">
                  <div className="media-img-wrap"></div>
                  <div className="media-body">
                    <span>
                      <i className="material-icons mr-1">account_circle</i>
                      {cookies.get("name")}
                      <i className="zmdi zmdi-chevron-down"></i>
                    </span>
                  </div>
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                data-dropdown-in="flipInX"
                data-dropdown-out="flipOutX"
              >
                <div className="dropdown-item">
                  <strong>
                    {cookies.get("name")}
                    <br />
                    {"(" + cookies.get("email") + ")"}
                  </strong>
                </div>
                <UpdateUserDetails parentCallback={this.handleAddCallback} />
                <ChangePassword />
                <a
                  styles={"cursor:pointer"}
                  className="dropdown-item"
                  target="_blank"
                  href={process.env.REACT_APP_API_LINK + "Payroll_QRG.pdf"}
                >
                  <i className="dropdown-icon zmdi zmdi-help-outline"></i>
                  Payroll QRG
                </a>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    auth.logout();
                  }}
                >
                  <i className="dropdown-icon zmdi zmdi-power"></i>
                  <span>Log out</span>
                </button>
              </div>
            </li>
          </ul>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-0"></ul>
          </div>
        </nav>

        {/* <!-- Vertical Nav --> */}
        <nav className="hk-nav hk-nav-light">
          <a id="hk_nav_close" className="hk-nav-close">
            <span className="feather-icon">
              <i data-feather="x"></i>
            </span>
          </a>
          <div className="nicescroll-bar">
            <div className="navbar-nav-wrap">
              <ul className="navbar-nav flex-column">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/app">
                    <i className="material-icons">dashboard</i>
                    <span className="nav-link-text">Dashboard</span>
                  </NavLink>
                </li>
              </ul>
              {this.state.permissions.timesheet > 0 &&
              this.state.permissions.transaction > 0 &&
              this.state.permissions.payroll > 0 &&
              this.state.permissions.ytd > 0 ? (
                <>
                  <hr className="nav-separator" />
                  <div className="nav-header">
                    <span>Modules</span>
                    <span>MD</span>
                  </div>
                </>
              ) : (
                ""
              )}
              <ul className="navbar-nav flex-column">
                {this.state.permissions.timesheet > 0 ? (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#Components_drp"
                    >
                      <i className="material-icons">schedule</i>
                      <span className="nav-link-text">Timesheets</span>
                    </a>
                    <ul
                      id="Components_drp"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/cutoff">
                              <i className="material-icons">pending_actions</i>
                              Upload Timesheets
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/timesheet_stg">
                              <i class="material-icons">alarm_on</i>
                              Review Timesheets
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {this.state.permissions.transaction > 0 ? (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#transactions"
                    >
                      <i className="material-icons">point_of_sale</i>
                      <span className="nav-link-text">Transactions</span>
                    </a>
                    <ul
                      id="transactions"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/transaction_upload"
                            >
                              <i className="material-icons">cloud_upload</i>
                              Transaction Upload
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/transaction">
                              <i className="ion ion-md-add-circle-outline"></i>
                              Maintain Earnings
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/transaction_ded">
                              <i className="ion ion-md-remove-circle-outline"></i>
                              Maintain Deductions
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/transaction_ot">
                            <i className="ion ion-md-remove-circle-outline"></i>Maintain
                              Pending OT
                            </NavLink>
                          </li>
                          <hr className="nav-separator" />
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/special_upload">
                              <i className="material-icons">cloud_upload</i>
                              Special Upload
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/special_pay">
                              <i className="material-icons">stars</i>Maintain
                              Special
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {this.state.permissions.payroll > 0 ? (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#content_drp"
                    >
                      <i className="material-icons">payments</i>
                      <span className="nav-link-text">Payroll Processing</span>
                    </a>
                    <ul
                      id="content_drp"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/reg_payroll">
                              <i className="material-icons">run_circle</i>
                              Regular Run
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/spl_payroll">
                              <i className="material-icons">stars</i>Special Run
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {this.state.permissions.ytd > 0 ? (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#ytda"
                    >
                      <i className="material-icons">tune</i>
                      <span className="nav-link-text">YTD Adjustments</span>
                    </a>
                    <ul
                      id="ytda"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/ytd_adjustment_upload"
                            >
                              <i className="material-icons">cloud_upload</i>
                              YTD Upload
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/ytd_adjustment">
                              <i className="material-icons">event</i>
                              Maintain YTD
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/ytd_run">
                              <i className="material-icons">edit_calendar</i>YTD
                              Run
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              {this.state.permissions.report > 0 ? (
                <>
                  <hr className="nav-separator" />
                  <div className="nav-header">
                    <span>Reports</span>
                    <span>RP</span>
                  </div>
                </>
              ) : (
                ""
              )}
              {this.state.permissions.report > 0 ? (
                <ul className="navbar-nav flex-column">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#payslip_drp"
                    >
                      <i className="material-icons">payment</i>
                      <span className="nav-link-text">Payslips</span>
                    </a>
                    <ul
                      id="payslip_drp"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/payslip">
                              <i className="material-icons">receipt_long</i>
                              <span className="nav-link-text">
                                Regular Payslip
                              </span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/sp_payslip">
                              <i className="material-icons">receipt</i>
                              <span className="nav-link-text">
                                Special Payslip
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#statutory_drp"
                    >
                      <i className="material-icons">card_membership</i>
                      <span className="nav-link-text">Employee Ledger</span>
                    </a>
                    <ul
                      id="statutory_drp"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/sss_contribution"
                            >
                              <i className="material-icons">assist_walker</i>
                              <span className="nav-link-text">SSS</span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/pagibig_contribution"
                            >
                              <i className="material-icons">
                                real_estate_agent
                              </i>
                              <span className="nav-link-text">PAGIBIG</span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/phic_contribution"
                            >
                              <i className="material-icons">
                                health_and_safety
                              </i>
                              <span className="nav-link-text">PHILHEALTH</span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/ytd">
                              <i className="material-icons">event</i>
                              <span className="nav-link-text">YTD</span>
                            </NavLink>
                          </li>
                          {/* <li className="nav-item">
                            <NavLink className="nav-link" to="/ytd/2316">
                              <i className="material-icons">event</i>
                              <span className="nav-link-text">BIR Form 2316</span>
                            </NavLink>
                          </li> */}
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#statutory2_drp"
                    >
                      <i className="material-icons">card_membership</i>
                      <span className="nav-link-text">
                        Accounting Registers
                      </span>
                    </a>
                    <ul
                      id="statutory2_drp"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/sss_pr_contribution"
                            >
                              <i className="material-icons">assist_walker</i>
                              <span className="nav-link-text">SSS</span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/pagibig_pr_contribution"
                            >
                              <i className="material-icons">
                                real_estate_agent
                              </i>
                              <span className="nav-link-text">PAGIBIG</span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/phic_pr_contribution"
                            >
                              <i className="material-icons">
                                health_and_safety
                              </i>
                              <span className="nav-link-text">PHILHEALTH</span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/ytd_pr">
                              <i className="material-icons">event</i>
                              <span className="nav-link-text">YTD</span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/pr_register">
                              <i className="material-icons">account_balance</i>
                              <span className="nav-link-text">
                                Payroll Register
                              </span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/bank_register">
                              <i className="material-icons">
                                account_balance_wallet
                              </i>
                              <span className="nav-link-text">
                                Bank Register
                              </span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/dept_register">
                              <i className="material-icons">
                                account_balance_wallet
                              </i>
                              <span className="nav-link-text">
                                Department Register
                              </span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/ed_register">
                              <i className="material-icons">
                                account_balance_wallet
                              </i>
                              <span className="nav-link-text">
                                Earning/Deduction Register
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item"></li>

                  {/* 
                <li className="nav-item">
                  <NavLink className="nav-link" to="/attendance_upload">
                    <i className="ion ion-md-bookmarks"></i>
                    <span className="nav-link-text">YTD</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/attendance_upload">
                    <i className="ion ion-md-bookmarks"></i>
                    <span className="nav-link-text">BIR Form 2316</span>
                  </NavLink>
                </li> */}
                </ul>
              ) : (
                ""
              )}
              <hr className="nav-separator" />
              <div className="nav-header">
                <span>Administration</span>
                <span>AD</span>
              </div>
              <ul className="navbar-nav flex-column">
                {this.state.permissions.master_data > 0 ? (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#md_drp"
                    >
                      <i className="ion ion-md-clipboard"></i>
                      <span className="nav-link-text">Master Data</span>
                    </a>
                    <ul
                      id="md_drp"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/company">
                              <i className="material-icons">business</i>
                              <span className="nav-link-text">
                                Company Information
                              </span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/bank">
                              <i className="material-icons">savings</i>
                              <span className="nav-link-text">Bank Data</span>
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/employee">
                              <i className="material-icons">accessibility</i>
                              <span className="nav-link-text">
                                Employee Data
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                {this.state.permissions.user_management > 0 ? (
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/user_management">
                      <i className="ion ion-md-people"></i>
                      <span className="nav-link-text">User Management</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {this.state.permissions.config > 0 ? (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#config_drp"
                    >
                      <i className="ion ion-md-cog"></i>
                      <span className="nav-link-text">Configuration</span>
                    </a>
                    <ul
                      id="config_drp"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/data_management">
                              <i className="material-icons">folder</i>Data
                              Management
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/basic_schema">
                              <i className="material-icons">schema</i>Basic
                              Schema
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/schema">
                              <i className="material-icons">schema</i>
                              Overtime Schema
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              {/* Clear Data */}
              {this.state.setting.enable_clear > 0 ? (
                <ul className="navbar-nav flex-column">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="collapse"
                      data-target="#clear_drp"
                    >
                      <i className="ion ion-md-trash"></i>
                      <span className="nav-link-text">Clear Data</span>
                    </a>
                    <ul
                      id="clear_drp"
                      className="nav flex-column collapse collapse-level-1"
                    >
                      <li className="nav-item">
                        <ul className="nav flex-column">
                          <li className="nav-item">
                            <ClearEntries />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                ""
              )}
              {/* Clear Data */}
            </div>
          </div>
        </nav>

        <div id="hk_nav_backdrop" className="hk-nav-backdrop"></div>
      </div>
    );
  }
}
